import { Grid } from "antd";
import TopNavBar from "../components/common/TopNavBar";
const { useBreakpoint } = Grid;


const TermsAndConditions = () => {
    const screens = useBreakpoint();


    return (
        <>
            <div style={{ padding: '0 30px' }}>
                <TopNavBar menu={true} />
            </div>

            <div style={{ width: screens.md ? "60%" : "90%", margin: "40px auto" }}>
                <h2 style={{ textAlign: "center", marginBottom: "50px" }}>The terms are between JAB and the Participant Jewellery Retailer.</h2>
                <ul>
                    <li>The Retailer has agreed to take part in the Bangalore Gold Festival organized by JAB to promote sales of the Retailers from <strong>15-10-2024</strong> to <strong>30-11-2024</strong>.</li>
                    <li>The Retailer will pay a participation fee to facilitate JAB to conduct the festival and spend the money collected towards Advertisement in print, electronic, social media, making attractive creatives to promote the show, printing all advertisement material, in-store branding material like Arch, Standees, Danglers, Table Tops, and Coupons. Also, to incur expenditures to conduct the festival successfully.</li>
                    <li>As a participant member of the festival (second party), the Retailer will get from JAB (First Party):<ul>
                        <li>Marketing material which the participant will display in his store.</li>
                        <li>The right to use the &ldquo;<strong>Bangalore Gold Festival</strong>&rdquo; logo on his communications.</li>
                        <li>His name in promotional material.d). Digital Coupons that he can distribute to his customers free of cost to promote/enhance the sales. At present, one coupon needs to be given for every sale of Rs. 5000/.</li>
                    </ul>
                    </li>
                    <li>The coupons are to be given only as add-ons without any additional consideration to the customers buyers of products in their (second party) shop. This is absolutely necessary. Any violation of this rule, and the participant will be removed from the scheme, and the money paid as a fee will be forfeited.</li>
                    <li>JAB will:<ul>
                        <li>Promote the BGF on various media like print, electronic, and social media.</li>
                        <li>Conduct a transparent system to select prize winners. If the winner does not collect the prize within the six-month period from the date of the draw, the prizes will be the property of JAB. No further claim is allowed.</li>
                    </ul>
                    </li>
                    <li>The Organizers like JAB Directors, AOJ, and Staff will not be eligible to participate in this Scheme. If issued, it amounts to a violation of the rules set out under the Scheme and will not be eligible for the prizes.</li>
                    <li>All the Participant jewellers need to issue tax invoices for all the sales; any violation of the law, and the participant jeweller will be held responsible.</li>
                    <li>The Force Majeure clause will be in force until the end of the festival.</li>
                    <li>The participating retailer is requested to adhere to the JAB Rules and regulations and do ethical business.</li>
                    <li>The participant jewellers need to pay the full participation fee with applicable taxes before the commencement of the festival; if any default occurs, he will not be part of the festival, and his participation fee will be refunded after deducting the collaterals cost delivered to him.</li>
                    <li>During the festival, the participating retailer is obliged to provide such information as required by JAB (first party).</li>
                    <li>All digital coupons are to be maintained and stored on participant computers; any loss of data, JAB will not be responsible.</li>
                    <li>The winning digital coupon should be accompanied by the valid tax invoice, also with the ID proof of the prize winner; otherwise, the prize will not be given to the winner, and it will be retained as the property of JAB.</li>
                    <li>The participation fee collected to conduct the BGF is non-refundable.</li>
                    <li>The Publicity Material, both physical and digital, provided to the Participants during the BGF is the sole property of JAB.</li>
                    <li>The dates of the festival/Number of days of the festival can be changed without any notice by JAB.</li>
                    <li>For any help during the BGF, the participating member can contact the JAB office: <strong>080-4127 6222 / 2221 1037</strong> or can write to the E-Mail: <strong>info@jab.org.in</strong> or can contact The Art of Jewellery office contact: <strong>98456 42844 / 98440 00543</strong>.</li>
                    <li>All the participation fees should be paid either by cheque / online transfer / RTGS; no cash is accepted.</li>
                    <li>All taxes are as applicable.</li>
                    <li>BGF is subject to prevailing government laws and policies.</li>
                    <li>All disputes are subject to Bengaluru jurisdiction. However, JAB will ensure Absolute Transparency while Conducting GBF. For further details, they are available on the JAB Website: www.jab.org.in</li>
                </ul>
            </div>


        </>
    )
}
export default TermsAndConditions;